import React from 'react';
import styled from 'styled-components';
import Avatar2 from 'src/assets/img/banner/Avatar2.png'
import { isMobile } from "react-device-detect";

export default function ClosePeopleBanner({
  className = ''
}: {
  className?: string;
}) {
  const clickHandler = () => {
    window.ym(49958389, 'reachGoal', 'click-bunner_get-to-know-relative_dawn');
    document.getElementById('add-person-button')?.click();
  }

  return (
    <Wrapper onClick={() => clickHandler()} className={className} isMobile>
      <section className="chronos_a cons">
        <div className='circle1'></div>
        <div className='circle2'></div>
        <div className='circle3'></div>
        <img src={Avatar2} />
        <div className="content">
          <h1>Узнайте больше о близких</h1>
          <p>Укажите имя, время и место рождения близкого вам человека и узнайте к чему он предрасположен согласно звездам</p>

          <button>
            <span>Узнать о близких</span>
            {!isMobile &&<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18 12.999V18.999C18 19.5295 17.7893 20.0382 17.4142 20.4132C17.0391 20.7883 16.5304 20.999 16 20.999H5C4.46957 20.999 3.96086 20.7883 3.58579 20.4132C3.21071 20.0382 3 19.5295 3 18.999V7.99902C3 7.46859 3.21071 6.95988 3.58579 6.58481C3.96086 6.20974 4.46957 5.99902 5 5.99902H11"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15 2.99902H21V8.99902"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 13.999L21 2.99902"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>}

          </button>
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div<{isMobile: boolean}>`
  cursor: pointer;
  user-select: none;
  background: #FEF0EB;
  border: 1px solid rgba(237, 104, 46, 0.1);
  border-radius: 8px;
  overflow: hidden;
  z-index: 1;
  height: ${isMobile ? '10rem' : '15rem'};
  margin-top: 1.5rem;


img {
    position: absolute;
    right: ${isMobile ? '-0.9rem' : '-1rem'};
    width: ${isMobile ? '15rem' : '22rem'};
    bottom: ${isMobile ? '0.8rem' : '-1.4rem'};
  }
  .circle1 {
    position: absolute;
  width: 650px;
  height: 650px;
  left: 137px;
  top: -186px;

  background: radial-gradient(48.01% 48.01% at right, #FEF0EB 20.6%, rgba(247, 228, 201, 0.3075) 100%);
  border-radius: 100rem;
  }

  .circle2 {
    position: absolute;
    width: 427px;
    height: 427px;
    left: 366px;
    top: -95px;

    background: radial-gradient(48.01% 48.01% at right, rgba(255, 245, 226, 0) 82.06%, rgba(247, 228, 201, 0.3075) 100%);
    border-radius: 90rem;
  }

  .circle3 {
    position: absolute;
  width: 14rem;
  height: 14rem;
  right: ${isMobile ? '0rem' : '2rem'};
  bottom: -2rem;
  

  background:#FFC997;
  border-radius: 100rem;
  }

  .content {
    width: 70%;
    z-index: 1;
  }

  .chronos_a {
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: 1.75rem 1.5rem 1.563rem;
    margin-bottom: 1.5rem;
    border-radius: 0.5rem;
  }

  .chronos_a h1 {
    font-size: ${isMobile ? '0.8rem' : '1.5rem'};
    color: var(--text-primary);
    font-weight: 500;
    margin: 0.75rem 0;   
  }

  .chronos_a p {
    max-width: 90%;
    margin: 0;
    font-size: ${isMobile ? '0.65rem' : '1.125rem'};
    line-height: ${isMobile ? '0.8rem' : '1.688rem'};
    color: var(--text-secondary);
  }

  .chronos_a.cons {

  }

  .chronos_a.cons h1 {
    margin-top: 0;
  }

  .chronos_a.cons .icon {
    svg {
      width: 5rem;
      height: 5rem;
    }
  }

  .chronos_a.cons .star {
    position: absolute;
    top: -60px;
    right: -60px;

    svg {
      width: 12rem;
    }
  }

    .chronos_a.cons button {
    appearance: none;
    border: none;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.125rem;
    height: ${isMobile && '2rem'};;
    font-size: 1.125rem;
    line-height: 27px;
    background-color: #ED682E;
    color: var(--colors-white);
    width: 80%;
    padding: 0.625rem 0 0.688rem;
    border-radius: 0.375rem;

    a {
      display: inline-block;
      width: 100%;
      color: inherit;
      font-size: inherit;
      line-height: inherit;
      text-decoration: none;
    }

    
    span {
      font-size: ${isMobile && '0.7rem'};
    }

    svg {
      vertical-align: middle;
      color: var(--colors-white);
      margin-left: 1rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;
