import React, { useCallback, useEffect } from 'react';
import { isMobile } from "react-device-detect";
import { connect } from 'react-redux';
import { Link, Redirect, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { PlusWithoutCircleIcon } from 'src/assets/img/icons';
import Loading from 'src/components/Loading/';
import Person from 'src/components/Person/';
import PersonAdd from 'src/components/PersonAdd/';
import PersonEdit from 'src/components/PersonEdit/';
import Confirm from 'src/components/ui/Confirm/';
import { fetchAPI_REST, mainApi } from 'src/helpers/api';
import { routes } from 'src/routes';
import { IFormation } from 'src/components/PrognosisList/compact';
import { searchParams } from 'src/helpers/utils';
import { history } from 'src/pages/app';
import { del, get } from 'src/store/actions/persons';

interface IPartnerParams {
    partner?: boolean;
    partner_id?: string;
}

function PersonList({
    persons,
    onGet,
    onDel,
    currentUserId
}: {
    persons: IFormation[],
    onGet(): void,
    onDel(id: number): void,
    currentUserId: number
}) {
    const { search } = useLocation()
    const sp = searchParams()
    const hash = sp.get('id')

    useEffect(() => {
        onGet();
    }, [onGet])

    useEffect(() => {
        if(persons.length > 0) {
            const formationsCount = persons.reduce((acc, next) => (next.formationCount > 0 ? acc + 1 : 0), 0)
            const optimizedFormationsCount = formationsCount < 3 ? formationsCount : 3;

            const mainUser = persons.find(item => item.main);
            const isUserNew = mainUser && mainUser.place.isEmpty;

            fetchAPI_REST(routes.PROFILE).then((data: any) => {
                const partnerId = data.partner;
                let partnerParams: IPartnerParams = {};
                // если пользователь авторизован
                partnerParams = {
                    partner: !!partnerId
                }

                if(partnerId) partnerParams.partner_id = partnerId;

                //@ts-ignore
                window.ym(49958389, 'userParams', {
                    Cart_bought: optimizedFormationsCount,
                    is_new: isUserNew,
                    ...partnerParams
                });

            })


        }


    }, [hash, persons])

    const cleanHash = useCallback(() => {
      sp.delete('id')
      history.push({pathname: window.location.pathname, search: sp.toString()})
    }, [sp])

    useEffect(() => {
      const checkPurchases = async (hash: string) => {
        if (persons.length === 0) return

        try {
          const result = await mainApi.checkFormation(hash)
          const personWithHash = persons.find(person => person.hash === hash)

          if (result.payed) {
            if (result.exist) {
              if (personWithHash) {
                cleanHash()
              } else {
                setError(true)
                window.ym(49958389,'reachGoal','Show_error_formation_was_paid')
              }
            } else {
              setAddIsOpened(true)
            }
          } else {
            cleanHash()
          }
        } catch(e) {
          console.error(`Checking formation ${hash} failed: ${e}`)
        }
    }

      if  (hash) {
        checkPurchases(hash)
      }
    }, [hash, persons, cleanHash])

    const [isEditShowConfirm, setIsEditShowConfirm] = React.useState<IFormation | boolean>(false);
    const [isDelShowConfirm, setIsDelShowConfirm] = React.useState<number>();
    const [addIsOpened, setAddIsOpened] = React.useState(false);
    const [editIsOpened, setEditIsOpened] = React.useState<IFormation | boolean>(false);
    const [error, setError] = React.useState(false);

    if (persons.length === 0) return <Loading />

    let _currentUserId = currentUserId;

    if (_currentUserId === 0) {
        if (hash && persons.find(person => person.hash === hash)) {
            const personIndex = persons.findIndex(person => person.hash === hash);
            return <Redirect to={{pathname: `/prognosis/${persons[personIndex].id}`, search}}></Redirect>;
        } else {
            return <Redirect push to={{pathname: `/prognosis/${persons[0].id}`, search}}></Redirect>
        }
    }

    const userForEdit = persons.find(item => item.id === _currentUserId)!;

    const closePersonList = () => {
        if(isMobile) {
            const listEl = document.getElementById('person-list');
            if (listEl) listEl.classList.remove('opened');
            setTimeout(() => window.location.reload(), 500);
        }
    }

    const editHandle = () => {
        setIsEditShowConfirm(true);
    }

    const onClickAddManHandler = () => {
        setAddIsOpened(true);
        //@ts-ignore
        window.ym(49958389,'reachGoal','Start_add_dawn')
    }

    const onCloseErrorConfirm = () => {
      setError(false)
      cleanHash()
    }

    return <PersonListContainer>
        <Header>Мои прогнозы</Header>
        <List>
            {persons.map(item => <li key={item.id}>
                <Link to={{pathname: `/prognosis/${item.id}`, search: sp.toString()}} onClick={closePersonList}>
                    <Person
                        user={item}
                        active={item.id === _currentUserId}
                        onEdit={() => editHandle()}
                        onDelete={() => setIsDelShowConfirm(item.id)}
                    />
                </Link>
            </li>
            )}
        </List>
        <AddPersonLink onClick={onClickAddManHandler} id="add-person-button"><PlusWithoutCircleIcon />Добавить человека</AddPersonLink>

        {
            isEditShowConfirm && <Confirm
                title='Построения сгорят'
                text='После редактирования любых данных, кроме имени и времени, все купленные построения сгорят'
                buttons={[
                    { name: 'Отменить', type: 'a', action: () => { setIsEditShowConfirm(false); } },
                    { name: 'Редактировать', type: 'button', action: () => { setIsEditShowConfirm(false); setEditIsOpened(userForEdit) } }
                ]}
            ></Confirm>
        }

        {
            isDelShowConfirm && <Confirm
                title='Удаление карты'
                text='После удаления карты все купленные построения сгорят'
                buttons={[
                    { name: 'Отменить', type: 'a', action: () => { setIsDelShowConfirm(undefined); } },
                    { name: 'Удалить', type: 'button', action: () => { setIsEditShowConfirm(false); onDel(userForEdit.id) } }
                ]}
            ></Confirm>
        }

        {addIsOpened && <PersonAdd hash={hash ?? ''} onClose={() => setAddIsOpened(false)} />}
        {editIsOpened && <PersonEdit onClose={() => setEditIsOpened(false)} user={userForEdit} />}

        {
          error && (
            <Confirm
              title='По данной ссылке уже был получен продукт'
              text='Повторное использование недоступно'
              buttons={[{ name: 'Закрыть', type: 'a', action: onCloseErrorConfirm}]}
            />
          )
        }
    </PersonListContainer >
}


const mapStateToProps = (state: any) => {
    return {
        persons: state.persons
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onGet: () => { dispatch(get()); },
        onDel: (id: number) => { dispatch(del(id)); }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonList);

const PersonListContainer = styled.article`
    @media (max-width: 768px) {
        background: var(--color-white);
        border-radius: 0.375rem;
        padding: 0.375rem;
        max-height: calc(100vh - 7rem);
        overflow: auto;
    }
`

const Header = styled.header`
    margin-bottom: 0.675rem;
    color: var(--color-gray);

    @media (max-width: 768px) {
        display: none;
    }
`

const List = styled.ul`
    margin: 0 0 1rem 0;
    list-style: none;
    padding: 0;

    @media (max-width: 768px) {
        margin: 0;
    }

    & > li {
        width: min-content;
        white-space: nowrap;
        margin-bottom: 0.375rem;
        cursor: pointer;

        @media (max-width: 768px) {
            width: 100%;
        }

        & > a {
            color: inherit;
            text-decoration: none;
        }
    }
`
const AddPersonLink = styled.button`
    display: flex;
    align-items: center;
    padding-left: 0.75rem;
    border: none;
    background: none;
    color: var(--color-gray);
    cursor: pointer;
    outline: none;


    @media (max-width: 768px) {
        background: var(--color-gray2);
        border-radius: 0.375rem;
        padding: 0.8rem;
        width: 100%;
        font-size: 1rem;
    }

    & svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.75rem;
    }

    &:hover {
        color: var(--color-black2);
    }
`
